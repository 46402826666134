import { _ } from '../utils.js'
import * as Sentry from '@sentry/browser'
import { isBrowserSupported } from '../utils/browser-support.js'
import config from '../config/main.js'

export default () => {
    const env = _('site-info', 'element').node()
    const environment = env?.dataset.environment ?? process.env.NODE_ENV
    const isLocal = environment === 'development' || environment === 'local'
    const isProduction = environment === 'production'
    const loggedIntoCMS = env?.dataset.c5 === 'true'
    if (isLocal || !isBrowserSupported() || loggedIntoCMS) {
        return false
    }
    if (config.sentryDSN === '') {
        console.warn('Sentry DSN is missing. Please add it to the config file.')
        return false
    }

    Sentry.init({
        dsn: config.sentryDSN,
        environment: environment,

        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        tracePropagationTargets: ['dev', 'staging'],

        // Session Replay
        replaysSessionSampleRate: isProduction ? 0.1 : 1.0,
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })

    // testSentryIsWorking() // uncomment to test Sentry is working
}

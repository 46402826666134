import YouTubeIframeLoader from 'youtube-iframe'
import { makeGtmCall } from '../utils/tracking.js'

export default class YoutubeVideo {
    constructor(self) {
        this.self = self
        const videoId = self.video.dataset.videoId
        const id = self.video.getAttribute('id')
        YouTubeIframeLoader.load((YT) => {
            this.player = new YT.Player(id, {
                height: '495',
                width: '880',
                videoId: videoId,
                playerVars: { rel: 0 },
                events: {
                    onReady: this.ytPlayerReady,
                },
            })
        })
    }

    ytPlayerReady = () => {
        this.self.controls.play.addEventListener('click', this.ytPlayVideo)
        this.self.controls.pause.addEventListener('click', this.ytPauseVideo)
    }

    ytPlayVideo = () => {
        this.player.playVideo()
        this.self.el.classList.add(this.self.activeClass)
        makeGtmCall(this.self.el)
    }

    ytPauseVideo = () => {
        this.player.pauseVideo()
        this.self.el.classList.remove(this.self.activeClass)
    }
}

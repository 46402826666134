/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
yarn add axios
import Listing from './modules/listing'
_('listing').nodes().map((el) => new Listing(el))

----------------------------------------------------------------------------//
Detail:
----------------------------------------------------------------------------//
every input/select with data-element="input"  will be iterated through to add to the endpoint url using the name="category" value="news" for values in makeEndpointUrl()
e.g `apiurl/?category=news&name2=value2`

----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
data-behaviour="listing"                     root element
data-behaviour="endpoint-input"              on change will take the value and do an api call regardless of other select/inputs values
data-behaviour="go"                          on click will make makeEndpointUrl
data-behaviour="go-to" data-endpoint="/url/" on click will make that api call
data-behaviour="clear-form"                  clears the form and getsResults
data-element="input"                         on change triggers makeEndpointUrl() and resets the pagination to 1 if it isn't the pagination that was the change trigger

*/
import config from '../config/main.js'
import { _ } from '../utils.js'
import { handleError } from '../utils/error-handler.js'
import { debounce } from '../utils/debounce.js'
import axios from 'axios'
import loading from '../templates/loading.js'
import card from '../templates/card.js'
import contactCard from '../templates/contact-card.js'
import podcastCard from '../templates/podcast-card.js'
import dealAlertCard from '../templates/deal-alert-card.js'
import insightCard from '../templates/insight-card.js'
import videoCard from '../templates/video-card.js'
import serviceCard from '../templates/service-card.js'
import helpCard from '../templates/help-card.js'
import eventCard from '../templates/event-card.js'

export default (el) => {
    const urlCheck = el.dataset.urlCheck ?? 'true'
    const onLoad = el.dataset.onload ?? 'true'
    const form = _('form', 'element').nodeFrom(el)
    let scrollUp = false
    let loadNo = 1
    const pagination = {
        container: _('pagination', 'element').nodeFrom(el),
        input: _('pagination-input').nodeFrom(el),
        select: _('pagination-select').nodeFrom(el),
        totalPages: _('pagination-total-pages', 'element').nodeFrom(el),
        nextPageTrigger: _('next-page').nodeFrom(el),
        previousPageTrigger: _('previous-page').nodeFrom(el),
    }
    const container = {
        filters: _('filters-container', 'element').nodeFrom(el),
        tabs: _('tab-container', 'element').nodeFrom(el),
        results: _('results-container', 'element').nodeFrom(el),
        searchedTerm: _('searched-term-container', 'element').nodeFrom(el),
        noResults: _('no-results', 'element').nodeFrom(el),
        peopleContainer: _('people-container', 'element').nodeFrom(el),
    }

    const input = {
        els: _('input', 'element').nodesFrom(el),
        endpoint: _('endpoint-input').nodeFrom(el),
        search: _('search-input').nodeFrom(el),
        clear: _('clear-form').nodeFrom(el),
    }

    const goToUrls = _('go-to-url').nodesFrom(el)
    const gos = _('go').nodesFrom(el)

    const reEnableModules = () => {}

    const handleSuccessTypeCard = (response) => {
        let resultsHTML = ''
        if (response.data) {
            resultsHTML +=
                '<div class="flex flex--4-col flex--equal-height flex--3-col@large flex--2-col@medium flex--1-col@small">'

            response.data.forEach((element, i) => {
                let item = ''
                if (response.data[i].tag === 'Podcast') {
                    item = podcastCard(response.data[i])
                } else if (response.data[i].tag === 'Deal Alert') {
                    item = dealAlertCard(response.data[i])
                } else if (
                    response.data[i].tag === 'Insight' ||
                    response.data[i].tag === 'Press'
                ) {
                    item = insightCard(response.data[i])
                } else if (
                    response.data[i].tag === 'Video' ||
                    response.data[i].tag === 'Webinar'
                ) {
                    item = videoCard(response.data[i])
                } else if (
                    response.data[i].tag === 'Event' ||
                    response.data[i].tag === 'Training'
                ) {
                    item = eventCard(response.data[i])
                } else {
                    item = card(response.data[i])
                }
                resultsHTML += item
            })
            resultsHTML += '</div>'
        }

        container.results.innerHTML = resultsHTML
        reEnableModules()
    }

    const handleSuccessTypePeople = (response) => {
        let resultsHTML = ''
        let searchedHTML = ''

        if (response.search) {
            searchedHTML +=
                '<div class="form-results__search"><p class="copy--small">Search results for:</p> <span class="form-results__search-term">"' +
                response.search +
                '"</span></div>'
        }
        if (response.data) {
            resultsHTML += '<div class="flex flex--2-col">'
            response.data.forEach((element, i) => {
                const item = contactCard(response.data[i])
                resultsHTML += item
            })
            resultsHTML += '</div>'
        }

        container.searchedTerm.innerHTML = searchedHTML
        container.results.innerHTML = resultsHTML
        reEnableModules()
    }

    const handleSuccessTypeServices = (response) => {
        let resultsHTML = ''
        if (response.data) {
            resultsHTML += '<div>'
            response.data.forEach((element, i) => {
                const item = serviceCard(response.data[i])
                resultsHTML += item
            })
            resultsHTML += '</div>'
        }

        container.results.innerHTML = resultsHTML
        reEnableModules()
    }

    const handleSuccessTypeHelp = (response) => {
        let resultsHTML = ''
        if (response.data) {
            resultsHTML += '<div>'
            response.data.forEach((element, i) => {
                const item = helpCard(response.data[i])
                resultsHTML += item
            })
            resultsHTML += '</div>'
        }

        container.results.innerHTML = resultsHTML
        reEnableModules()
    }

    const resetPaginator = () => {
        if (pagination.input) {
            pagination.input.value = 1
        }
    }

    const showLoading = () => {
        if (container.noResults) {
            container.noResults.classList.add('u-hidden')
        }
        container.results.innerHTML = loading()
    }

    const hideLoading = () => {
        container.results.innerHTML = ''
    }

    const changeParams = () => {
        if (urlCheck === 'true') {
            const url = new URL(window.location)
            const params = new URLSearchParams(url.search)
            let modalParam = ''
            if (params.has('modal')) {
                modalParam = `&modal=${params.get('modal')}`
            }

            window.history.replaceState(
                null,
                null,
                `${window.location.pathname}?${
                    input.endpoint.value.split('?')[1]
                }${modalParam}${window.location.hash}`,
            )
        }
    }

    const buildPagination = (response) => {
        if (!pagination) {
            return false
        }
        if (
            !response.pagination ||
            !response.pagination.totalPages ||
            response.pagination.totalPages < 2
        ) {
            pagination.container.classList.add('u-hidden')
            return false
        } else {
            pagination.container.classList.remove('u-hidden')
        }

        if (response.pagination.nextPage === null) {
            pagination.nextPageTrigger.classList.add('button--hidden')
        } else {
            pagination.nextPageTrigger.classList.remove('button--hidden')
        }

        if (response.pagination.previousPage === null) {
            pagination.previousPageTrigger.classList.add('button--hidden')
        } else {
            pagination.previousPageTrigger.classList.remove('button--hidden')
        }
        let paginationHTML = ''
        for (let i = 1; i <= response.pagination.totalPages; i++) {
            paginationHTML += `<option value="${i}" ${
                response.pagination.currentPage === i ? 'selected' : ''
            }>${i}</option>`
        }

        pagination.totalPages.innerHTML = response.pagination.totalPages
        pagination.select.innerHTML = paginationHTML
    }

    const handleNoResults = () => {
        container.noResults.classList.remove('u-hidden')
        hideLoading()
    }

    const scrollToResultsTop = () => {
        window.scrollTo({
            top:
                container.results.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                30,
            behavior: 'smooth',
        })
    }

    const handleSuccess = (response) => {
        if (scrollUp) {
            scrollToResultsTop()
        }
        hideLoading()
        if (el.dataset.type === 'card') {
            return handleSuccessTypeCard(response)
        }
        if (el.dataset.type === 'people') {
            return handleSuccessTypePeople(response)
        }
        if (el.dataset.type === 'services') {
            return handleSuccessTypeServices(response)
        }
        if (el.dataset.type === 'help') {
            return handleSuccessTypeHelp(response)
        }

        return console.warn('missing data type')
    }

    const getResults = () => {
        showLoading()
        axios({
            method: el.dataset.action || 'get',
            url: input.endpoint.value,
        })
            .then((response) => {
                if (el.dataset.type === 'people') {
                    const url = new URL(window.location)
                    const params = new URLSearchParams(url.search)
                    if (
                        params.has('showResults') &&
                        params.get('showResults') === 'true'
                    ) {
                        params.delete('showResults')
                        container.peopleContainer.classList.remove('u-hidden')
                    }
                }

                changeParams()
                buildPagination(response.data)

                if (response.status !== 200) {
                    return handleError(response)
                }

                if (
                    !response.data.data.length &&
                    !Object.keys(response.data.data).length
                ) {
                    return handleNoResults()
                }

                return handleSuccess(response.data)
            })
            .catch((error) => {
                handleError(error, container.results)
            })
    }

    const makeEndpointUrl = () => {
        const startEndpoint = input.endpoint.value.split('?')
        let endpoint = startEndpoint[0]
        const inputs = _('input', 'element').nodesFrom(el)

        if (!(scrollUp || loadNo === 1)) {
            resetPaginator()
        }

        inputs?.forEach((inputItem, i) => {
            const name = inputItem.getAttribute('name')

            if (name === null) {
                if (config.debug) {
                    console.warn('input without a name', inputItem)
                }
                return false
            }
            if (!i) {
                endpoint += '?'
            }
            if (inputItem.type !== 'radio' && inputItem.type !== 'checkbox') {
                endpoint += `&${name}=${inputItem.value}`
            }
            if (inputItem.type === 'radio' && inputItem.checked) {
                endpoint += `&${name}=${
                    inputItem.value !== 'on'
                        ? inputItem.value
                        : inputItem.checked
                }`
            }
            if (inputItem.type === 'checkbox' && inputItem.checked) {
                endpoint += `&${name}=${
                    inputItem.value !== 'on'
                        ? inputItem.value
                        : inputItem.checked
                }`
            }
        })

        endpoint = endpoint.replace('?&', '?')
        input.endpoint.value = encodeURI(endpoint)

        loadNo++
        getResults()
    }

    const changeInputsToParams = () => {
        const url = new URL(window.location)
        const params = new URLSearchParams(url.search)
        let paramsPresent = false
        params.forEach((value, key) => {
            if (key === 'null' || key === 'modal' || value === 'null') {
                if (config.debug) {
                    console.warn('you have a null key/value')
                }
                return false
            }

            paramsPresent = true

            const inputEl = el.querySelector(`[name="${key}"]`)
            const radioInput = el.querySelector(`[value="${value}"]`)
            if (inputEl?.type === 'checkbox') {
                inputEl.checked = true
            }
            if (inputEl?.type === 'radio') {
                radioInput.checked = true
            }
            if (
                inputEl?.type === 'text' ||
                inputEl?.type === 'number' ||
                inputEl?.type === 'select-one' ||
                inputEl?.type === 'hidden'
            ) {
                value = value.replace(/ /gi, '+')

                inputEl.value = value
            }
            if (inputEl?.type === 'search') {
                inputEl.value = value
            }
        })

        if (paramsPresent) {
            container?.peopleContainer?.classList.remove('u-hidden')
            return makeEndpointUrl()
        }
        if (!paramsPresent && onLoad === 'true') {
            makeEndpointUrl()
        }
    }

    const search = (e) => {
        if (e.target.value.length > 2 || e.target.value.length === 0) {
            if (container.peopleContainer) {
                container.peopleContainer.classList.remove('u-hidden')
            }
            resetPaginator()
            makeEndpointUrl()
        }
    }

    const formSubmit = (e) => {
        e.preventDefault()
        makeEndpointUrl()
    }

    const clearForm = () => {
        form.reset()

        setTimeout(() => {
            if (container.peopleContainer) {
                container.peopleContainer.classList.add('u-hidden')
            }
            resetPaginator()
            makeEndpointUrl()
        }, 500)
    }

    const setEndpointUrl = (e) => {
        input.endpoint.value = e.currentTarget.dataset.endpoint
        getResults()
    }

    const goNextPage = (e) => {
        pagination.input.value++
        scrollUp = true
        makeEndpointUrl(e)
    }

    const goPreviousPage = (e) => {
        pagination.input.value--
        scrollUp = true
        makeEndpointUrl(e)
    }

    const changePaginationInput = () => {
        pagination.input.value = pagination.select.value
        scrollUp = true
        makeEndpointUrl()
    }

    // Event listeners
    goToUrls.forEach((goto) => {
        goto.addEventListener('click', setEndpointUrl)
    })

    gos.forEach((go) => {
        go.addEventListener('click', makeEndpointUrl)
    })

    if (input.clear) {
        input.clear.addEventListener('click', clearForm)
    }

    input.els.forEach((inputElement) => {
        inputElement.addEventListener('change', function () {
            if (container.peopleContainer) {
                container.peopleContainer.classList.remove('u-hidden')
            }
            resetPaginator()
            makeEndpointUrl()
        })
    })

    if (input.search) {
        input.search.addEventListener('input', debounce(search, 500))
    }
    input.endpoint.addEventListener('change', getResults)
    form.addEventListener('submit', formSubmit)
    if (urlCheck === 'true') {
        changeInputsToParams()
    }

    if (onLoad === 'true' && urlCheck === 'false') {
        makeEndpointUrl()
    }

    pagination.nextPageTrigger?.addEventListener('click', goNextPage)
    pagination.previousPageTrigger?.addEventListener('click', goPreviousPage)
    pagination.select?.addEventListener('input', changePaginationInput)
}

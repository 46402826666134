// https://github.com/qodesmith/datepicker
import datepicker from 'js-datepicker'
export default (el) => {
    datepicker(el, {
        formatter: (input, date) => {
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const day = String(date.getDate()).padStart(2, '0')
            const year = date.getFullYear()
            const value = `${year}-${month}-${day}`
            input.value = value
        },
    })
}

/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
yarn add youtube-iframe
import Video from './modules/video'
_('video', 'element').nodes().map((el) => new Video(el))

----------------------------------------------------------------------------
Behaviour attributes:
----------------------------------------------------------------------------
data-behaviour="video-play"      - will hide the image and play the video
data-behaviour="video-pause"     - visually hidden but can be used to trigger elsewhere to pause video
                                   .eg modal close
                                   _('video-pause').nodeFrom(modal).dispatchEvent(new Event('click'))

----------------------------------------------------------------------------
Other attributes:
----------------------------------------------------------------------------
data-element="video"              - is the root element
data-type="video | youtube"       - determines what type the video is and how to handle it
data-active-class="video--active" - the active class toggle on play

----------------------------------------------------------------------------
Youtube specific attributes:
----------------------------------------------------------------------------
data-element="video-player"       - used for tye youtube is the container where the iframe will be appended
data-video-id="yxz"               - the id of the youtube video to be played

*/

import { _ } from '../utils.js'
import { makeGtmCall } from '../utils/tracking.js'
import YoutubeVideo from './video-youtube.js'

export default class Video {
    constructor(el) {
        this.el = el
        this.video = _('video-player', 'element').nodeFrom(this.el)
        this.controls = {
            play: _('video-play').nodeFrom(this.el),
            pause: _('video-pause').nodeFrom(this.el),
        }
        this.activeClass = el.dataset.activeClass
        this.el.dataset.gtmEvent = this.el.dataset.gtmEvent || 'video-play'

        if (this.el.dataset.type === 'youtube') {
            this.el.dataset.gtmName =
                this.el.dataset.gtmName || this.video.dataset.videoId
            new YoutubeVideo(this)
        }

        if (this.el.dataset.type === 'video') {
            this.player = this.video
            this.el.dataset.gtmName =
                this.el.dataset.gtmName ||
                this.video.querySelector('source').src
            this.controls.play.addEventListener('click', this.playVideo)
            this.controls.pause.addEventListener('click', this.pauseVideo)
        }

        if (this.el.dataset.type === 'video-banner') {
            this.toggleVideo = _('video-banner-play').nodeFrom(this.el)
            this.videoVolume = _('video-volume').nodeFrom(this.el)
            this.volumeSlider = _('volume-slider').nodeFrom(this.el)
            this.volumeToggle = _('volume-toggle').nodeFrom(this.el)
            this.fullscreen = _('video-fullscreen').nodeFrom(this.el)
            this.el.dataset.gtmName =
                this.el.dataset.gtmName ||
                this.video.querySelector('source').src

            if (this.video.muted) {
                this.volumeToggle.children[0].href.baseVal =
                    '/application/themes/rawnet/app/images/sprite.svg#muted'
            } else {
                this.volumeToggle.children[0].href.baseVal =
                    '/application/themes/rawnet/app/images/sprite.svg#sound'
            }

            this.video.addEventListener('click', () => {
                this.setVolumeSlide(this.volumeSlider, this.video.volume * 100)
            })

            this.toggleVideo.addEventListener('click', () => {
                this.setVolumeSlide(this.volumeSlider, this.video.volume * 100)
                this.video.paused ? this.video.play() : this.video.pause()
            })

            this.video.addEventListener('play', () => {
                this.togglePlayIcon(this.toggleVideo, 'pause_icon')
            })

            this.video.addEventListener('pause', () => {
                this.togglePlayIcon(this.toggleVideo, 'play_icon')
            })

            this.fullscreen.addEventListener('click', () => {
                if (this.video.requestFullScreen) {
                    this.video.requestFullScreen()
                } else if (this.video.webkitRequestFullScreen) {
                    this.video.webkitRequestFullScreen()
                } else if (this.video.mozRequestFullScreen) {
                    this.video.mozRequestFullScreen()
                } else {
                    this.video.webkitEnterFullscreen()
                    this.video.enterFullscreen()
                }
            })

            this.volumeToggle.addEventListener('click', () => {
                if (window.screen.width >= 1024) {
                    this.video.muted = !this.video.muted

                    if (this.video.muted) {
                        this.volumeToggle.children[0].href.baseVal =
                            '/application/themes/rawnet/app/images/sprite.svg#muted'
                    } else {
                        this.volumeToggle.children[0].href.baseVal =
                            '/application/themes/rawnet/app/images/sprite.svg#sound'
                    }
                }

                if (window.screen.width <= 1023) {
                    if (getComputedStyle(this.volumeSlider).opacity === '1') {
                        this.volumeSlider.style.opacity = 0
                        this.volumeSlider.style.maxWidth = 0
                        this.videoVolume.style.maxWidth = '70px'
                        this.videoVolume.style.background = 'transparent'
                    } else {
                        this.volumeSlider.style.opacity = 1
                        this.volumeSlider.style.maxWidth = null
                        this.videoVolume.style.maxWidth = null
                        this.videoVolume.style.background = null
                    }
                }
            })

            this.volumeSlider.addEventListener('input', (e) => {
                this.video.muted = false
                this.video.volume = e.target.value / 100
                this.setVolumeSlide(this.volumeSlider, e.target.value)

                if (e.target.value === '0') {
                    this.volumeToggle.children[0].href.baseVal =
                        '/application/themes/rawnet/app/images/sprite.svg#muted'
                } else {
                    this.volumeToggle.children[0].href.baseVal =
                        '/application/themes/rawnet/app/images/sprite.svg#sound'
                }
            })
        }
    }

    playVideo = () => {
        this.player.play()
        this.el.classList.add(this.activeClass)
        makeGtmCall(this.el)
    }

    pauseVideo = () => {
        this.player.pause()
        this.el.classList.remove(this.activeClass)
    }

    setVolumeSlide = (el, videoVolume) => {
        el.value = videoVolume
        el.setAttribute(
            'style',
            `background: linear-gradient(to right,#FFFFFF,#FFFFFF ${videoVolume}%, #e2e2e2 ${videoVolume}%)`,
        )
    }

    togglePlayIcon = (el, icon) => {
        el.innerHTML = `
            <svg width="12" height="15" class="video__svg">
                <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#${icon}"></use>
            </svg>
        `
    }
}

import { _ } from '../utils.js'
import { handleError } from '../utils/error-handler.js'
import axios from 'axios'
import loading from '../templates/loading.js'

export default class AddressLookup {
    constructor(el) {
        this.el = el
        this.input = _('lookup-input', 'element').nodeFrom(this.el)
        this.addressResults = _('address-results', 'element').nodeFrom(this.el)
        this.trigger = _('lookup').nodeFrom(this.el)
        this.container = {
            loading: _('loading-container', 'element').nodeFrom(this.el),
            addressFields: _('address-container', 'element').nodeFrom(this.el),
            results: _('results-container', 'element').nodeFrom(this.el),
        }

        this.trigger.addEventListener('click', this.autocompleteAddresses)
    }

    autocompleteAddresses = (e) => {
        e.preventDefault()
        if (this.input.value.length < 3) {
            this.addressResults.innerHTML = ''
            return false
        }
        this.container.loading.innerHTML = loading()
        axios({
            method: 'get',
            url: `https://api.getaddress.io/find/${this.input.value}?api-key=ay5hC8XOvUut3DEPBYTpBQ30712&expand=true`,
        })
            .then((response) => {
                this.container.loading.innerHTML = ''
                response.data.addresses.forEach((prediction) => {
                    const li = document.createElement('li')
                    const predictedAddress = prediction.formatted_address
                        .filter(Boolean)
                        .join(', ')
                    li.classList.add('address-results__item')
                    li.innerHTML = `<button type="button" class="address-results__link" data-behaviour="prediction-choice">
                            ${predictedAddress}
                        </button>`
                    this.addressResults.appendChild(li)
                    li.addEventListener(
                        'click',
                        this.populateFields(prediction),
                    )
                })
            })
            .catch((error) => {
                this.addressResults.innerHTML = ''
                const responseRaw = error?.request.responseText
                const responseObj = JSON.parse(responseRaw)
                error.response.message = responseObj.Message.replace(
                    'Bad Request:',
                    '',
                )
                handleError(
                    error,
                    error?.response,
                    this.container.results,
                    this.container.loading,
                )
            })
    }

    populateFields = (prediction) => {
        return () => {
            this.addressResults.innerHTML = ''
            this.container.addressFields?.classList.remove('u-hidden')
            for (const [id, value] of Object.entries(prediction)) {
                const item = this.el.querySelector(`[data-element="${id}"]`)
                if (item) {
                    item.value = value
                }
            }
            _('postcode', 'element').nodeFrom(this.el).value = this.input.value
        }
    }
}

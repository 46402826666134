function imageData(data) {
    if (data.image !== null) {
        let markup = ''
        markup += `
            <picture>
                <img class="card__image" loading="lazy" src="${data.image.src}" alt=${data.image.alt}" />
            </picture>`
        return markup
    } else {
        return ''
    }
}
// prettier-ignore
const template = (data) => `
    <div class="flex__item">
        <article class="card card--video">
            <a class="card__link" href="${data.url}">
                <div class="card__image-container">
                    ${imageData(data)}
                    <span class="card__play-icon">
                        <svg width="68" height="68">
                            <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#play">
                            </use>
                        </svg>
                    </span>
                </div>
                <div class="card__content">
                    <div>
                        <span class="tag u-mb-8">${data.tag}</span>
                        <h2 class="card__heading copy--large copy--dark u-mb-35">${data.title}</h2>
                    </div>
                    <time class="copy--tiny" datetime="${data.dateTime}">${data.date}  |  ${data.mediaDuration} minute watch</time>
                </div>
            </a>
        </article>
    </div>
`
export default template

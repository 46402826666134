/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import FileUpload from './modules/file-upload'
_('file-upload').nodes().map((el) => new FileUpload(el))
----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
data-element="accordion"           - the base element
data-behaviour="accordion-trigger" - toggles the class
data-element="accordion-target"    - has the class that gets toggled
*/

import { _ } from '../utils.js'

export default class FileUpload {
    constructor(el) {
        this.el = el
        this.input = _('upload-input', 'element').nodeFrom(this.el)
        this.uploadFileName = _('upload-filename', 'element').nodeFrom(this.el)
        this.deleteFile = _('clear-file', 'element').nodeFrom(this.el)
        this.classes = {
            deleteFileActiveClass: 'file-upload__clear--active',
        }

        this.input.addEventListener('change', this.checkFileSize)
        this.input.addEventListener('change', this.findFileName)
        this.deleteFile.addEventListener('click', this.clearFile)
    }

    checkFileSize = (e) => {
        const file = e.currentTarget.files[0]
        const fileSize = file.size
        const maxFileSize = 1500000
        if (fileSize > maxFileSize) {
            // eslint-disable-next-line no-alert
            alert(
                'File size is too big. Please upload a file smaller than 1.5MB',
            )
            this.clearFile()
        }
    }

    findFileName = (e) => {
        if (e.currentTarget.value) {
            const inputValue = e.currentTarget.value.split('\\').pop()
            this.uploadFileName.textContent = inputValue
            this.deleteFile.classList.add(this.classes.deleteFileActiveClass)
            e.preventDefault
        }
    }

    clearFile = () => {
        this.uploadFileName.textContent = ''
        this.input.value = ''
        this.deleteFile.classList.remove(this.classes.deleteFileActiveClass)
    }
}

function imageData(data) {
    if (data.image !== null) {
        let markup = ''
        markup += `
            <picture>
                <img class="card__image" loading="lazy" src="${data.image.src}" alt=${data.image.alt}" />
            </picture>`
        return markup
    } else {
        return ''
    }
}
// prettier-ignore
const template = (data) => `
    <div class="flex__item">
        <article class="card">
            <a class="card__link" href="${data.url}">
                <div class="card__image-container">
                    ${imageData(data)}
                </div>
                <div class="card__content">
                    <div>
                        <span class="tag u-mb-8">${data.tag}</span>
                        <h2 class="card__heading copy--large copy--dark u-mb-35">${data.title}</h2>
                    </div>
                    <p class="copy--tiny">${data.attendLabel}</p>
                    <time class="copy--tiny" datetime="${data.dateTime}">${data.date}  |  ${data.time}</time>
                </div>
            </a>
        </article>
    </div>
`
export default template

function options(data) {
    if (data) {
        let markup = ''
        for (let i = 0; i < data.length; i++) {
            markup += `<div class="flex__item">
            <a class="service-card__link" href="${data[i].url}">${data[i].title}</a>
        </div>`
        }
        return markup
    } else {
        return ''
    }
}

const template = (data) => `
    <div class="service-card">
        <div class="flex flex--1-col@medium">
            <div class="flex__item flex__item--40">
                <div class="u-mr-20@min-medium">
                    <h4 class="heading--2 u-mb-25">${data.title}</h4>
                    <a class="link link--align-left" href="${data.url}">
                        Learn more about ${data.title}
                        <svg width="20" height="20" class="link__svg">
                            <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#chevron_in_circle"></use>
                        </svg>
                    </a>
                </div>
            </div>
            <div class="flex__item flex__item--60">
                <p class="heading--5 heading--copy u-mb-35">${data.description}</p>
                <div class="flex flex--2-col">
                    ${options(data.children)}
                </div>
            </div>
        </div>
    </div>
`
export default template

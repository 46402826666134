// prettier-ignore
const template = (data) => `
    <div class="flex__item">
        <article class="card card--deal-alert">
            <a class="card__link" href="${data.url}">
                <svg width="304" height="213" class="card__pattern">
                    <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#deal_alert_small_cropped">
                    </use>
                </svg>
                <div class="card__content">
                    <div class="u-mb">
                        <span class="tag u-mb-8">${data.tag}</span>
                        <h2 class="card__heading copy--large copy--white">${data.title}</h2>
                    </div>
                    <time class="copy--tiny copy--white" datetime="${data.dateTime}">${data.date}</time>
                </div>
            </a>
        </article>
    </div>
`
export default template

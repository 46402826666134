function moreExpertises(data) {
    if (data.moreExpertises > 0) {
        let markup = ''
        markup +=
            '<span class="u-opacity-4">+ ' +
            data.moreExpertises +
            ' more</span>'
        return markup
    } else {
        return ''
    }
}

function imageData(data) {
    if (data.image !== null) {
        let markup = ''
        markup += `${data.img}`
        return markup
    } else {
        return ''
    }
}

// prettier-ignore
const template = (data) => `
<div class="flex__item u-mb-48">
<article class="contact-card ">
<div class="flex flex--2-col flex--1-col@large u-z-1">
    <div class="flex__item">
        <div>
            <div class="contact-card__details">
                ${imageData(data)}
                <div class="u-flex u-flex-col u-hidden@large">
                    <a class="contact-card__link" href="tel:${data.formattedTelephone}">
                        <svg width="26" height="26" class="contact-card__icon">
                            <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#phone"></use>
                        </svg>
                        <span class="">${data.formattedTelephone}</span>
                    </a>
                    <a class="contact-card__link" href="mailto:${data.email}">
                        <svg width="26" height="26" class="contact-card__icon">
                            <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#email"></use>
                        </svg>
                        <span class="">Send email</span>
                    </a>
                    <a class="contact-card__link" href="${data.vCardUrl}">
                        <svg width="26" height="26" class="contact-card__icon">
                            <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#details"></use>
                        </svg>
                        <span class="">Contact details</span>
                    </a>
                    <a class="contact-card__link" href="${data.linkedInUrl}">
                        <svg width="26" height="26" class="contact-card__icon">
                            <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#linkedin"></use>
                        </svg>
                        <span class="">LinkedIn</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="flex__item">
        <div class="contact-card__profile">
            <div>
                <h2 class="heading--4">${data.name}</h2>
                <p class="copy--tiny u-mb-18 u-mb-20@large">${data.role}</p>
                <p class="contact-card__description">${data.description}</p>
                <p class="copy--tiny u-mb-10">Expertise</p>
                <p class="contact-card__description">${data.expertises} ${moreExpertises(data)}</p>
            </div>
            <div class="u-flex u-flex-col u-hidden@min-large u-mb">
                <a class="contact-card__link" href="tel:${data.formattedTelephone}">
                    <svg width="26" height="26" class="contact-card__icon">
                        <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#phone"></use>
                    </svg>
                    <span class="">${data.formattedTelephone}</span>
                </a>
                <a class="contact-card__link" href="mailto:${data.email}">
                    <svg width="26" height="26" class="contact-card__icon">
                        <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#email"></use>
                    </svg>
                    <span class="">Send email</span>
                </a>
                <a class="contact-card__link" href="${data.vCardUrl}">
                    <svg width="26" height="26" class="contact-card__icon">
                        <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#details"></use>
                    </svg>
                    <span class="">Contact details</span>
                </a>
                <a class="contact-card__link" href="${data.linkedInUrl}">
                    <svg width="26" height="26" class="contact-card__icon">
                        <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#linkedin"></use>
                    </svg>
                    <span class="">LinkedIn</span>
                </a>
            </div>
            <a class="button button--max-full button--auto-width" href="${data.url}">View Full Profile</a>
        </div>
    </div>
</div>
</article>
</div>

`
export default template

/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import FormValidate from './modules/form-validate'
_('', 'validate').nodes().map((el) => new FormValidate(el))

----------------------------------------------------------------------------
Base attributes:
----------------------------------------------------------------------------
data-validate      - root attribute
*/
import { _ } from './../utils.js'
import { makeGtmCall } from '../utils/tracking.js'
import Validate from '../modules/validate.js'

export default (form) => {
    form.dataset.validated = 'false'
    let pristine = new Validate(form)
    const restartTrigger = _('restart-validation').node()
    const resetTrigger = _('clear-validation').node()

    const resetPristine = () => {
        pristine.reset()
    }

    const restartPristine = () => {
        setTimeout(() => {
            if (form.dataset.validated === 'false') {
                pristine = new Validate(form)
            }
            if (form.dataset.validated === 'failed') {
                pristine = new Validate(form)
                pristine.validate()
            }
        }, 100)
    }

    const formSubmit = (e) => {
        const valid = pristine.validate()

        if (!valid) {
            e.stopImmediatePropagation()
            e.preventDefault()
        }

        if (valid) {
            form.dataset.gtmEvent = form.dataset.gtmEvent || 'form-submit'
            form.dataset.gtmName = form.dataset.gtmName || form.action

            makeGtmCall(form)
        }
    }

    form.addEventListener('submit', formSubmit)
    restartTrigger?.addEventListener('input', restartPristine)
    resetTrigger?.addEventListener('input', resetPristine)
}

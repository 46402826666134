import { _ } from '../utils.js'
import config from '../config/main.js'
import messageContainer from '../templates/message.js'
import closeMessage from '../modules/close-message.js'

export const handleError = (response, container) => {
    console.warn(response)
    const resp = response?.response?.data
    const errorMessageFromResponse = resp?.message
    let errorHTML = `<p>${errorMessageFromResponse ?? config.message.error}</p>`
    if (resp?.errors?.length) {
        errorHTML = ''
        resp?.errors?.map((el) => {
            errorHTML += `<p>${el}</p>`
        })
    }

    container.innerHTML = messageContainer(errorHTML)
    _('message', 'element')
        .nodesFrom(container)
        .map((el) => closeMessage(el))
}

function author(data) {
    if (data.author !== null) {
        let markup = ''
        markup += `<div class="author author--card">
        ${data?.author?.image ? `<picture>
            <img class="author__image" src="${data?.author?.image?.src}" alt="${data?.author?.image?.alt}" />
            </picture>` : ''}
            <div class="author__content">
                <p class="copy--tiny">By ${data.author.firstname} ${data.author.lastName}</p>
                <time class="copy--tiny" datetime="${data.dateTime}">${data.date}</time>
            </div>
        </div>`
        return markup
    } else {
        return ''
    }
}
// prettier-ignore
const template = (data) => `
    <div class="flex__item">
        <article class="card">
            <a class="card__link" href="${data.url}">
                <div class="card__image-container">
                    <picture>
                        <img class="card__image" loading="lazy" src="${data.image.src}" alt=${data.image.alt}" height="304" width="199"/>
                    </picture>
                </div>
                <div class="card__content">
                    <div>
                        <span class="tag u-mb-8">${data.tag}</span>
                        <h2 class="card__heading copy--large copy--dark u-mb-35">${data.title}</h2>
                    </div>
                    ${author(data)}
                </div>
            </a>
        </article>
    </div>
`
export default template

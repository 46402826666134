/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
yarn add axios
import Listing from './modules/listing'
_('listing').nodes().map((el) => new Listing(el))

----------------------------------------------------------------------------//
Detail:
----------------------------------------------------------------------------//
every input/select with data-element="input"  will be iterated through to add to the endpoint url using the name="category" value="news" for values in makeEndpointUrl()
e.g `apiurl/?category=news&name2=value2`

----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
data-behaviour="listing"                     root element
data-behaviour="endpoint-input"              on change will take the value and do an api call regardless of other select/inputs values
data-behaviour="go"                          on click will make makeEndpointUrl
data-behaviour="go-to" data-endpoint="/url/" on click will make that api call
data-behaviour="clear-form"                  clears the form and getsResults
data-element="input"                         on change triggers makeEndpointUrl() and resets the pagination to 1 if it isn't the pagination that was the change trigger

*/

import { _ } from '../utils.js'
import EntranceTransition from './../modules/entrance-transitions.js'
import { makeGtmCall } from '../utils/tracking.js'
import { debounce } from '../utils/debounce.js'
import LazyLoad from './../modules/lazy-load.js'
import axios from 'axios'
import loading from '../templates/loading.js'
import contactCard from '../templates/contact-card.js'
import card from '../templates/card.js'
import podcastCard from '../templates/podcast-card.js'
import dealAlertCard from '../templates/deal-alert-card.js'
import insightCard from '../templates/insight-card.js'
import videoCard from '../templates/video-card.js'
import serviceCard from '../templates/service-card.js'
import helpCard from '../templates/help-card.js'

export default class Listing {
    constructor(el) {
        this.el = el
        this.scrollUp = false
        this.form = _('form', 'element').nodeFrom(this.el)

        this.message = {
            success: 'Thanks!',
            error: 'Something has gone quite wrong, please try to refresh your browser or contact us.',
        }
        this.pagination = {
            container: _('pagination', 'element').nodeFrom(this.el),
            input: _('pagination-input').nodeFrom(this.el),
            totalPages: _('total-pages', 'element').nodeFrom(el),
            nextPageTrigger: _('next-page').nodeFrom(el),
            previousPageTrigger: _('previous-page').nodeFrom(el),
        }
        this.container = {
            filters: _('filters-container', 'element').nodeFrom(el),
            results: _('results-container', 'element').nodeFrom(this.el),
            noResults: _('no-results', 'element').nodeFrom(this.el),
            searchedTerm: _('searched-term-container', 'element').nodeFrom(el),
        }
        this.input = {
            els: _('input', 'element').nodesFrom(this.el),
            endpoint: _('endpoint-input').nodeFrom(this.el),
            search: _('search-input').nodeFrom(this.el),
            clear: _('clear-form').nodeFrom(this.el),
        }

        this.goToUrls = _('go-to-url').nodesFrom(this.el)
        this.gos = _('go').nodesFrom(this.el)

        this.goToUrls.forEach((goto) => {
            goto.addEventListener('click', this.setEndpointUrl)
        })

        this.gos.forEach((go) => {
            go.addEventListener('click', this.makeEndpointUrl)
        })

        if (this.input.clear) {
            this.input.clear.addEventListener('click', this.clearForm)
        }

        this.input.els.forEach((input) => {
            input.addEventListener('change', this.makeEndpointUrl)
        })
        if (this.input.search) {
            this.input.search.addEventListener(
                'input',
                debounce(this.search, 500),
            )
        }
        this.input.endpoint.addEventListener('change', this.getResults)
        this.form.addEventListener('submit', this.formSubmit)
        if (this.el.dataset.onload !== 'false') {
            this.makeEndpointUrl()
        }

        this.pagination.nextPageTrigger?.addEventListener(
            'click',
            this.goNextPage,
        )
        this.pagination.previousPageTrigger?.addEventListener(
            'click',
            this.goPreviousPage,
        )
    }

    search = (e) => {
        if (e.target.value.length > 2 || e.target.value.length === 0) {
            this.resetPaginator()
            this.makeEndpointUrl()
        }
    }

    formSubmit = (e) => {
        e.preventDefault()
        this.makeEndpointUrl()
    }

    showLoading = () => {
        if (this.container.noResults) {
            this.container.noResults.classList.add('u-hidden')
        }
        this.container.results.innerHTML = loading()
    }

    hideLoading = () => {
        this.container.results.innerHTML = ''
    }

    clearForm = () => {
        this.form.reset()
    }

    setEndpointUrl = (e) => {
        this.input.endpoint.value = e.currentTarget.dataset.endpoint
        this.getResults()
    }

    goNextPage = (e) => {
        this.pagination.input.value++
        this.scrollUp = true
        this.makeEndpointUrl(e)
    }

    goPreviousPage = (e) => {
        this.pagination.input.value--
        this.scrollUp = true
        this.makeEndpointUrl(e)
    }

    resetPaginator = () => {
        if (this.pagination.input) {
            this.pagination.input.value = 1
        }
    }

    makeEndpointUrl = (e) => {
        this.startEndpoint = this.input.endpoint.value.split('?')
        this.endpoint = this.startEndpoint[0]
        const inputs = _('input', 'element').nodesFrom(this.el)
        if (inputs.length) {
            inputs.map((el, i) => {
                if (!i) {
                    this.endpoint += '?'
                }
                if (el.type !== 'radio' && el.type !== 'checkbox') {
                    this.endpoint += `&${el.getAttribute('name')}=${el.value}`
                }
                if (el.type === 'radio' && el.checked) {
                    this.endpoint += `&${el.getAttribute('name')}=${el.value}`
                }
                if (el.type === 'checkbox') {
                    this.endpoint += `&${el.getAttribute('name')}=${el.checked}`
                }
            })
        }
        if (this.pagination.container && !this.pagination.input.value) {
            this.endpoint += `&${this.pagination.input.getAttribute('name')}=1`
        }
        this.endpoint = this.endpoint.replace('?&', '?')
        this.input.endpoint.value = encodeURI(this.endpoint)

        if (
            !!e &&
            !!e.currentTarget &&
            e.currentTarget.dataset.behaviour === 'pagination'
        ) {
            this.getResults(true)
        } else {
            this.getResults(false)
            this.resetPaginator()
        }
    }

    getResults = (scrollTop = false) => {
        this.showLoading()
        axios({
            method: this.el.dataset.action || 'get',
            url: this.input.endpoint.value,
        })
            .then((response) => {
                if (this.pagination) {
                    this.buildPagination(response.data)
                }
                if (response.status !== 200) {
                    return this.handleError(response)
                }

                if (
                    !response?.data.length &&
                    !Object.keys(response?.data?.data).length
                ) {
                    return this.handleNoResults()
                }

                return this.handleSuccess(response.data, scrollTop)
            })
            .catch((error) => {
                this.handleError(error)
            })
    }

    scrollToResultsTop = () => {
        window.scrollTo({
            top:
                this.container.results.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                30,
            behavior: 'smooth',
        })
    }

    buildPagination = (response) => {
        if (
            !response.pagination ||
            !response.pagination.totalPages ||
            response.pagination.totalPages < 2
        ) {
            this.pagination.container.classList.add('u-hidden')
            return false
        } else {
            this.pagination.container.classList.remove('u-hidden')
        }
        let paginationHTML = ''
        for (let i = 1; i <= response.pagination.totalPages; i++) {
            paginationHTML += `<option value="${i}" ${
                response.pagination.currentPage === i ? 'selected' : ''
            }>${i}</option>`
        }
        this.pagination.totalPages.innerHTML = response.pagination.totalPages
        this.pagination.input.innerHTML = paginationHTML
    }

    handleSuccess = (response, scrollTop = false) => {
        if (scrollTop) {
            this.scrollToResultsTop()
        }
        this.hideLoading()
        if (this.el.dataset.type === 'card') {
            return this.handleSuccessTypeCard(response)
        }
        if (this.el.dataset.type === 'people') {
            return this.handleSuccessTypePeople(response)
        }
        if (this.el.dataset.type === 'services') {
            return this.handleSuccessTypeServices(response)
        }
        if (this.el.dataset.type === 'help') {
            return this.handleSuccessTypeHelp(response)
        }

        return this.handleError('missing data type')
    }

    handleSuccessTypeCard = (response) => {
        let resultsHTML = ''
        if (response.data) {
            resultsHTML += '<div class="flex flex--4-col flex--equal-height">'
            response.data.forEach((el, i) => {
                let item = ''
                if (response.data[i].tag === 'Podcast') {
                    item = podcastCard(response.data[i])
                } else if (response.data[i].tag === 'Deal Alert') {
                    item = dealAlertCard(response.data[i])
                } else if (
                    response.data[i].tag === 'Insight' ||
                    response.data[i].tag === 'Press'
                ) {
                    item = insightCard(response.data[i])
                } else if (
                    response.data[i].tag === 'Video' ||
                    response.data[i].tag === 'Webinar'
                ) {
                    item = videoCard(response.data[i])
                } else {
                    item = card(response.data[i])
                }
                resultsHTML += item
            })
            resultsHTML += '</div>'
        }

        this.container.results.innerHTML = resultsHTML
        this.reEnableModules()
    }

    handleSuccessTypePeople = (response) => {
        let resultsHTML = ''
        let searchedHTML = ''

        if (response.search) {
            searchedHTML +=
                '<div class="form-results__search"><p class="copy--small">Search results for:</p> <span class="form-results__search-term">"' +
                response.search +
                '"</span></div>'
        }
        if (response.data) {
            resultsHTML += '<div class="flex flex--2-col">'
            response.data.forEach((element, i) => {
                const item = contactCard(response.data[i])
                resultsHTML += item
            })
            resultsHTML += '</div>'
        }

        this.container.searchedTerm.innerHTML = searchedHTML
        this.container.results.innerHTML = resultsHTML
        this.reEnableModules()
    }

    handleSuccessTypeServices = (response) => {
        let resultsHTML = ''
        if (response.data) {
            resultsHTML += '<div>'
            response.data.forEach((element, i) => {
                const item = serviceCard(response.data[i])
                resultsHTML += item
            })
            resultsHTML += '</div>'
        }

        this.container.results.innerHTML = resultsHTML
        this.reEnableModules()
    }

    handleSuccessTypeHelp = (response) => {
        let resultsHTML = ''
        if (response.data) {
            resultsHTML += '<div>'
            response.data.forEach((element, i) => {
                const item = helpCard(response.data[i])
                resultsHTML += item
            })
            resultsHTML += '</div>'
        }

        this.container.results.innerHTML = resultsHTML
        this.reEnableModules()
    }

    reEnableModules = () => {
        _('gtm-click')
            .nodesFrom(this.container.results)
            .map((el) => {
                el.addEventListener('click', () => {
                    makeGtmCall(el)
                })
            })
        _('', 'entrance')
            .nodesFrom(this.container.results)
            .map((el) => new EntranceTransition(el))

        _('image', 'lazyload')
            .nodesFrom(this.container.results)
            .map((el) => new LazyLoad(el))
    }

    handleNoResults = () => {
        this.container.noResults.classList.remove('u-hidden')
        this.hideLoading()
    }

    handleError = (err, defaultMessage = this.message.error) => {
        console.warn(err)
        this.hideLoading()

        let errorHTML = `<p>${defaultMessage}</p>`
        if (err && err.errors) {
            err.errors.map((el) => {
                errorHTML += `<p>${el}</p>`
            })
        }
        console.warn(errorHTML)
        // this.container.results.innerHTML = errorMessageContainer(errorHTML)
    }
}

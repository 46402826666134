import { _ } from '../utils.js'
import dayjs from 'dayjs'

export default class DebtCalculator {
    constructor(el) {
        this.el = el
        this.resultsContainer = _('results-container', 'element').nodeFrom(
            this.el,
        )
        // Inputs
        this.interestRate = _('interest-rate', 'element').nodeFrom(this.el)
        this.baseRate = _('base-rate', 'element').nodeFrom(this.el)
        this.startDate = _('start-date', 'element').nodeFrom(this.el)
        this.endDate = _('end-date', 'element').nodeFrom(this.el)
        this.initialAmount = _('initial-amount', 'element').nodeFrom(this.el)

        // Results
        this.daysDisplay = _('days-display', 'element').nodeFrom(this.el)
        this.totalDueDisplay = _('total-due-display', 'element').nodeFrom(
            this.el,
        )
        this.dailyRateDisplay = _('daily-rate-display', 'element').nodeFrom(
            this.el,
        )
        this.interestCalculatedDisplay = _(
            'interest-calculated-display',
            'element',
        ).nodeFrom(this.el)

        // Actions
        this.reset = _('reset', 'element').nodeFrom(this.el)

        // Event Listeners
        this.resetTrigger = _('clear-validation').nodeFrom(this.el)
        this.el.addEventListener('submit', this.calculate)
        this.reset.addEventListener('click', this.clearForm)
    }

    calculate = (e) => {
        e.preventDefault()
        // Values
        const interest =
            parseFloat(this.interestRate.value) +
            parseFloat(this.baseRate.value)
        const startDateValue = dayjs(this.startDate.value)
        const endDateValue = dayjs(this.endDate.value)
        const dayDifference = endDateValue.diff(startDateValue, 'day')

        // Calculations
        const dailyRate = (this.initialAmount.value * (interest / 100)) / 365
        const totalInterest = dayDifference * dailyRate
        const totalInterestDecimals = totalInterest.toFixed(2)
        const totalDue = (+totalInterest + +this.initialAmount.value).toFixed(2)
        // Update Results
        this.daysDisplay.innerHTML = dayDifference
        this.dailyRateDisplay.innerHTML = dailyRate.toFixed(2)
        this.totalDueDisplay.innerHTML = totalDue
        this.interestCalculatedDisplay.innerHTML = totalInterestDecimals

        // Scroll to results on mobile
        window.scrollTo({
            top:
                this.resultsContainer.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                30,
            behavior: 'smooth',
        })
    }

    clearForm = () => {
        this.resetTrigger.dispatchEvent(new Event('input'))
        this.el.reset()

        this.daysDisplay.innerHTML = '0'
        this.dailyRateDisplay.innerHTML = '0'
        this.totalDueDisplay.innerHTML = '0.00'
        this.interestCalculatedDisplay.innerHTML = '0.00'
    }
}

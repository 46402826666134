/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
yarn add pristinejs
*/
import Pristine from 'pristinejs'
export default class FormValidate {
    constructor(el) {
        this.form = el
        this.pristineConfig = {
            // class of the parent element where the error/success class is added
            classTo: 'js-validate-item',
            errorClass: 'form__error',
            successClass: 'form__success',
            // class of the parent element where error text element is appended
            errorTextParent: 'js-validate-item',
            // type of element to create for the error text
            errorTextTag: 'p',
            // class of the error text element
            errorTextClass: 'error',
        }

        this.pristine = new Pristine(this.form, this.pristineConfig)
        return this.pristine
    }
}

/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import Sticky from './modules/sticky'
_('sticky').nodes().map((el) => new Sticky(el))
----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
data-element="sticky"             - Sticky outer container
data-element="header"             - Header content to animate
*/

import { _ } from '../utils.js'

export default (el) => {
    const header = _('header', 'element').nodeFrom(el)
    const headerTop = _('header__top', 'element').nodeFrom(el)
    const hasOffset = el.classList.contains('header__sticky--offset')

    if (hasOffset) {
        const offsetSize = header.clientHeight
        el.style.marginBottom = `${offsetSize}px`
    }

    const stickScrollPos = 60
    let lastScrollTop = 0

    const stick = () => {
        const newScrollTop =
            window.pageYOffset || document.documentElement.scrollTop
        const mediaLarge = window.matchMedia('(max-width: 1279px)').matches

        if (newScrollTop > lastScrollTop) {
            if (newScrollTop > stickScrollPos) {
                header.classList.remove('header__unstuck')
                header.classList.add('header__stuck')
                headerTop.style.borderBottom = '1px solid rgba(#ffffff, 0.2)'
                header.style.marginTop = '-60px'
                if (mediaLarge) {
                    headerTop.style.display = 'block'
                    header.style.marginTop = '0'
                }
            }
        } else {
            if (newScrollTop < stickScrollPos) {
                header.classList.remove('header__stuck')
                header.classList.add('header__unstuck')
                headerTop.style.display = 'block'
                header.style.marginTop = '0'
            }
        }
        lastScrollTop = newScrollTop <= 0 ? 0 : newScrollTop
    }
    document.addEventListener('scroll', stick, { passive: true })
}

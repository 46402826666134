import { _, removeClasses } from '../utils.js'
import config from '../config/main.js'

class Menu {
    constructor() {
        this.menu = _('menu').node()
        this.menuItem = _('menu-item', 'element').nodes()
        this.menuUnFocus = _('menu-unfocus', 'element-2').nodes()
        this.menuList = _('menu-list', 'element').nodes()
        this.menuLink = _('menu-link', 'element').nodes()
        this.subTrigger = _('sub-trigger', 'element').nodes()
        this.backTrigger = _('back-trigger', 'element').nodes()
        this.menuTab = _('menu-tab').nodes()
        this.menuTabContainers = document.querySelectorAll(
            _('menu-tab-container').str(),
        )
        this.page = _('page').node()
        this.classes = {
            menuItemActive: 'nav__item--active',
            menuTabButtonActive: 'nav__tab--active',
            menuTabItemActiveToggle: 'nav__mobile-tab--active',
            pageActive: 'page--overlay',
        }

        this.navTabletBreak = window.matchMedia('(max-width: 1025px)')
        this.navMobileBreak = window.matchMedia(
            `(max-width: ${config.breakpoint.small}px)`,
        )

        this.toggleNavFunctionality()
        this.navMobileBreak.addListener(this.toggleNavFunctionality)
        this.navTabletBreak.addListener(this.toggleNavFunctionality)

        this.subTrigger.map((el) => {
            el.addEventListener('click', this.addActiveClass)
        })
        this.backTrigger.map((el) => {
            el.addEventListener('click', this.removeActiveClass)
        })
        this.menuTab.map((el) => {
            el.addEventListener('click', this.toggleMenuTabs)
        })
        this.menuList.map((el) => {
            el.addEventListener('mouseenter', this.bgToggleClass)
            el.addEventListener('mouseleave', this.bgToggleClass)
        })
    }

    toggleNavFunctionality = () => {
        let device = 'desktop'
        const isTouch = 'ontouchstart' in document.documentElement

        if (this.navMobileBreak.matches) {
            device = 'mobile'
        }
        if (!this.navMobileBreak.matches && isTouch) {
            device = 'tablet'
        }
        switch (device) {
            case 'mobile':
                this.removeTabAccessibility()
                this.removeTapStoppers()
                break
            case 'tablet':
                this.addTapStoppers()
                break
            case 'desktop':
                this.removeTapStoppers()
                this.addTabAccessibility()
                break
            default:
                console.warn('no device detected')
        }
    }

    addTabAccessibility = () => {
        this.menuLink.map((el) => {
            el.addEventListener('focus', this.addActiveClass)
        })
        this.menuUnFocus.map((el) => {
            el.addEventListener('focus', this.removeActiveClassAccessibility)
        })
    }

    removeTabAccessibility = () => {
        this.menuLink.map((el) => {
            el.removeEventListener('focus', this.addActiveClass)
        })
        this.menuUnFocus.map((el) => {
            el.removeEventListener('focus', this.removeActiveClassAccessibility)
        })
    }

    addTapStoppers = () => {
        this.menuLink.map((el) => {
            el.addEventListener('click', this.preventFirstTap)
        })
    }

    removeTapStoppers = () => {
        this.menuLink.map((el) => {
            el.removeEventListener('click', this.preventFirstTap)
        })
    }

    preventFirstTap = (e) => {
        e.preventDefault()
        this.addTapStoppers()
        e.currentTarget.removeEventListener('click', this.preventFirstTap)
    }

    addActiveClass = (e) => {
        e.currentTarget
            .closest(_('menu-item', 'element').str())
            .classList.add(this.classes.menuItemActive)
    }

    removeActiveClassAccessibility = () => {
        removeClasses(this.menuItem, this.classes.menuItemActive)
    }

    removeActiveClass = (e) => {
        e.currentTarget
            .closest(_('menu-item', 'element').str())
            .classList.remove(this.classes.menuItemActive)
    }

    toggleMenuTabs = (e) => {
        const target = e.currentTarget.dataset.target

        this.menuTab.forEach((el) => {
            el.classList.remove(this.classes.menuTabButtonActive)
        })

        this.menuTabContainers.forEach((el) => {
            el.classList.remove(this.classes.menuTabItemActiveToggle)
        })

        e.currentTarget.classList.add(this.classes.menuTabButtonActive)
        document
            .querySelector(_(target, 'element').str())
            .classList.add(this.classes.menuTabItemActiveToggle)
    }

    bgToggleClass = (e) => {
        if (e.type === 'mouseenter') {
            this.bgAddClass()
        } else {
            removeClasses(this.menuItem, this.classes.menuItemActive)
            this.page.classList.remove(this.classes.pageActive)
        }
    }
    bgAddClass = () => {
        this.page.classList.add(this.classes.pageActive)
    }
}

export default Menu

import { _ } from '../utils.js'

export default class SubmitCV {
    constructor(el) {
        this.el = el
        this.restartTrigger = _('restart-validation').node()
        this.detailsToggle = _('details-toggle').nodesFrom(this.el)
        this.detailsToggleSection = _('details-toggle-section').nodesFrom(
            this.el,
        )
        this.toggleRequiredInput = _('toggle-required').nodesFrom(this.el)
        this.classes = {
            hidden: 'u-hidden',
        }
        this.detailsToggle.map((detailsEl) => {
            detailsEl.addEventListener('change', this.showDetailsSection)
        })

        this.toggleRequiredInput.map((toggleEl) => {
            toggleEl.addEventListener('change', this.toggleRequired)
        })
    }

    showDetailsSection = (e) => {
        this.detailsToggleSection.map((el) => {
            const input = el.getElementsByTagName('input')
            for (let i = 0; i < input.length; i++) {
                input[i].removeAttribute('required')
            }
            el.classList.add(this.classes.hidden)
        })

        const trigger = e.target
        const target = _(trigger.dataset.target, 'element').node()
        target.classList.remove(this.classes.hidden)

        const input = target.getElementsByTagName('input')
        for (let i = 0; i < input.length; i++) {
            input[i].setAttribute('required', '')
        }
        this.restartTrigger.dispatchEvent(new Event('input'))
    }

    toggleRequired = (e) => {
        const trigger = e.target
        const target = _(trigger.dataset.target, 'element').node()

        if (trigger.value === '1') {
            target.required = true
        }
        this.restartTrigger.dispatchEvent(new Event('input'))
    }
}

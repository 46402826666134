// Sentry
import sentry from './modules/sentry.js'
sentry()

// Dependencies
import { _ } from './utils.js'
import lazyLoad from './modules/lazy-load.js'
import slider from './modules/slider.js'
import Tabs from './modules/tabs.js'
import ToggleClass from './modules/toggle-class.js'
import Menu from './modules/menu.js'
import FileUpload from './modules/file-upload.js'
import SubmitCV from './modules/submit-CV.js'
import AddressLookup from './modules/address-lookup.js'
import formValidate from './modules/form-validate.js'
import Video from './modules/video.js'
import listing from './modules/listing.js'
import OldListing from './modules/old-listing.js'
import inputDatepicker from './modules/input-datepicker.js'
import DebtCalculator from './modules/debt-calculator.js'
import Modal from './modules/modal.js'
import sticky from './modules/sticky.js'

_('image', 'lazyload')
    .nodes()
    .map((el) => lazyLoad(el))
_('slider')
    .nodes()
    .map((el) => slider(el))
_('tab-trigger')
    .nodes()
    .map((el) => new Tabs(el))
_('toggle-class')
    .nodes()
    .map((el) => new ToggleClass(el))
_('menu')
    .nodes()
    .map((el) => new Menu(el))
_('file-upload')
    .nodes()
    .map((el) => new FileUpload(el))
_('submit-cv')
    .nodes()
    .map((el) => new SubmitCV(el))
_('address-lookup')
    .nodes()
    .map((el) => new AddressLookup(el))
_('', 'validate')
    .nodes()
    .map((el) => formValidate(el))
_('video', 'element')
    .nodes()
    .map((el) => new Video(el))
_('listing')
    .nodes()
    .map((el) => listing(el))
_('old-listing')
    .nodes()
    .map((el) => new OldListing(el))
_('datepicker')
    .nodes()
    .map((el) => inputDatepicker(el))
_('debt-calculator')
    .nodes()
    .map((el) => new DebtCalculator(el))
_('modal')
    .nodes()
    .map((el) => new Modal(el))
_('sticky')
    .nodes()
    .map((el) => sticky(el))

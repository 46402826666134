/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import Tabs from './modules/tabs'
_('tab-trigger').nodes().map((el) => new Tabs(el))

----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
data-element="tabs"             - overall container
data-behaviour="tab-trigger"    - individual tab items to trigger change
data-element="tab-button"       - optional button on mobile to hide and show tabs
*/

import { _, removeClasses } from '../utils.js'
import { makeGtmCall } from '../utils/tracking.js'
class Tabs {
    constructor(el) {
        this.el = el
        this.tabsContainer = this.el.closest(_('tabs', 'element').str())
        this.activeTabClass = this.el.dataset.class
        this.activeTabContentClass = 'test'

        this.activeTabContentClass = _('tab-container', 'element').nodeFrom(
            this.tabsContainer,
        ).dataset.class

        this.allTabTriggers = _('tab-trigger').nodesFrom(this.tabsContainer)
        this.allTabContainers = _('tab-container', 'element').nodesFrom(
            this.tabsContainer,
        )

        this.tabButton = _('tab-button', 'element').nodeFrom(this.tabsContainer)
        window.addEventListener('hashchange', this.triggerFromHash, {
            once: true,
        })
        this.el.addEventListener('click', this.changeHash)
        this.triggerFromHash()
    }
    /**
     * @param  {object} el
     */

    changeHash = (e) => {
        e.preventDefault()
        const hash = e.currentTarget.getAttribute('href') || ''
        if (this.tabButton) {
            this.tabButton.innerHTML = e.currentTarget.innerHTML
        }
        history.pushState(null, null, `${hash}`)
        this.triggerFromHash()
    }

    triggerFromHash = () => {
        const allTabs = _('tab-trigger').nodes()

        if (window.location.hash === '' || !allTabs.length) {
            return false
        }

        allTabs.map((el) => {
            if (el.getAttribute('href') === window.location.hash) {
                this.toggleTabs(el)
            }
        })
    }

    /**
     * @param  {object} el
     */

    toggleTabs = (el) => {
        const currentTab = el
        if (this.tabButton) {
            this.tabButton.innerHTML = el.innerHTML
        }
        const isTabOpen = currentTab.classList.contains(this.activeTabClass)
        if (isTabOpen) {
            return false
        }
        const tabTarget = currentTab.getAttribute('href')
        currentTab.dataset.gtmEvent = 'tab-open'
        currentTab.dataset.gtmName = tabTarget
        makeGtmCall(el)
        removeClasses(this.allTabTriggers, this.activeTabClass)
        removeClasses(this.allTabContainers, this.activeTabContentClass)
        currentTab.classList.add(this.activeTabClass)
        document
            .querySelector('[data-id=' + tabTarget.slice(1) + ']')
            .classList.add(this.activeTabContentClass)
    }
}
export default Tabs

/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import EntranceTransitions from './modules/entrance-transitions'
_('', 'entrance').nodes().map((el) => new EntranceTransition(el))

----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
data-entrance                   - element to watch and add class of .animate  to
data-root-margin="0"            - default 0
data-threshold="-5%"            - default -5%
data-direction="alternate"      - default null | alternate to repeat animations on scroll up

*/

export default class EntranceTransitions {
    constructor(el) {
        this.el = el

        this.config = {
            rootMargin: el.dataset.rootMargin || '-5%',
            threshold: parseFloat(el.dataset.threshold) || 0,
        }

        this.direction = this.el.dataset.direction
        this.page = document.querySelector('.page')
        this.class = 'animate'
        if (
            !window.IntersectionObserver ||
            (this.page && this.page.classList.contains('page-edit-mode'))
        ) {
            el.classList.toggle(this.class)
            return false
        }

        this.toggleAnimateClass()
    }

    toggleAnimateClass = () => {
        const thisDefault = this
        const observer = new IntersectionObserver((entries, self) => {
            const entry = entries[0]
            if (entry.isIntersecting) {
                entry.target.classList.add(thisDefault.class)
                if (thisDefault.direction !== 'alternate') {
                    self.unobserve(entry.target)
                }
            } else {
                if (thisDefault.direction === 'alternate') {
                    entry.target.classList.remove(thisDefault.class)
                }
            }
        }, this.config)

        observer.observe(this.el)
    }
}

/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import LazyLoad from './modules/lazy-load'
_('image', 'lazyload').nodes().map((el) => new LazyLoad(el))
----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
data-src="replacement_image"
*/

import config from '../config/main.js'

export default (el) => {
    const lazyLoadConfig = {
        rootMargin: '300px',
        threshold: 0,
    }

    const swapSrc = () => {
        const imageSrc = el.dataset.src
        el.setAttribute('src', imageSrc)

        if (el.parentNode.nodeName === 'PICTURE') {
            const sourceEl = [...el.parentNode.querySelectorAll('source')]
            sourceEl.forEach((source) => {
                source.setAttribute('srcset', source.dataset.src)
            })
        }
    }

    const lazySwap = () => {
        const observer = new IntersectionObserver((entries, self) => {
            const entry = entries[0]
            if (entry.isIntersecting) {
                swapSrc(entry.target)
                self.unobserve(entry.target)
            }
        }, lazyLoadConfig)

        observer.observe(el)
    }

    if (
        !config.browserSupports.lazyload ||
        !config.browserSupports.intersectionObserver ||
        config.pageInEditMode
    ) {
        swapSrc()
        return false
    }

    lazySwap()
}

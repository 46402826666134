export default {
    debug: false,
    trackingDebug: false,
    pageInEditMode: !!document.querySelector('.page-edit-mode'),
    projectSupports: {
        ie11: false,
    },
    browserSupports: {
        cssSupports: window.CSS,
        objectFit: window.CSS.supports('object-fit', 'cover'),
        lazyload: 'loading' in HTMLImageElement.prototype,
        intersectionObserver: window.IntersectionObserver,
    },
    brightcove: {
        accountID: '',
        playerId: '',
    },
    breakpoint: {
        small: '568',
        medium: '767',
        large: '1024',
        xlarge: '1280',
    },
    message: {
        error: 'Something has gone quite wrong, please try to refresh your browser or contact us.',
        success: 'Thanks!',
    },
}

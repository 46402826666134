// prettier-ignore
const template = (data) => `
    <div class="flex__item">
        <article class="card card--podcast">
            <a class="card__link" href="${data.url}">
                <div class="card__content">
                    <div class="u-mb-70">
                        <span class="tag u-mb-8">${data.tag}</span>
                        <h2 class="card__heading copy--large copy--dark u-mb-half">${data.title}</h2>
                        <p class="copy--default">${data.description}</p>
                    </div>
                    <span class="link link--align-left">
                        Listen Now
                        <svg width="20" height="20" class="link__svg">
                            <use xlink:href="/application/themes/rawnet/app/images/sprite.svg#chevron_in_circle"></use>
                        </svg>
                    </span>
                </div>
            </a>
        </article>
    </div>
`
export default template
